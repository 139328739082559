/* @tailwind base;
@tailwind components;
@tailwind utilities; */
.text-primary {
    color: #d9534f !important; /* Custom primary color */
  }
  
  .text-primary:hover {
    color: #b52a2a !important;
  }
  
  .display-4 {
    font-size: 2.5rem;
  }
  